.mat-checked {
  .mat-slide-toggle-bar {
    background-color: #478b47 !important;
  }

  .mat-slide-toggle-thumb {
    background-color: #7cf27c !important;
  }
}

.show-inactive {
  .mat-checked {
    .mat-slide-toggle-bar {
      background-color: #f54215 !important;
    }

    .mat-slide-toggle-thumb {
      background-color: #dc6143 !important;
    }
  }
}

md-checkbox .md-icon {
  background: green;
}
md-checkbox.md-default-theme.md-checked .md-icon {
  background: green;
}
