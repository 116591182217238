@media (min-width: 640px) {
  app-content-layout {
    .toggle-sidenav {
      cursor: pointer;
    }

    .sidenav-collapse {
      .sidenav {
        width: 3.5rem !important;

        .sidenav-title-content {
          padding-left: 0 !important;
          span {
            margin-left: 9px !important;
          }
        }

        app-sitenav-menu {
          nav {
            span {
              display: none !important;
            }
          }
        }
      }

      .header {
        padding-left: 3.5rem !important;
      }

      .content {
        padding-left: 3.5rem !important;
      }

      .toggle-sidenav {
        .collapse-icon {
          display: none !important;
        }

        .expand-icon {
          display: inline !important;
        }

        .collapse-text {
          display: none !important;
        }
      }
    }
  }
}
