:root {
  // gray scale
  --gray-10: #f9f9f9;
  --gray-20: #e4e4e4;
  --gray-40: #dedede;
  --gray-60: #d1d1d1;
  --gray-140: #4a4a4a;

  // blue scale
  --blue-20: #b3d1e8;
  --blue-140: #104670;
}
