.mat-tab-list {
  background: #dedede;
}

.mat-tab-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.mat-tab-body-content {
  border: 1px solid #dedede;
}

.mat-tab-label {
  min-width: 130px !important;
}
