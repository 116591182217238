mbsc-datepicker {
  mbsc-input {
    &:before,
    &:after {
      border: none !important;
    }
  }
}

.mbsc-picker {
  z-index: 100000 !important;
}
