.switch-calendar-view {
  zoom: 0.85;

  &::before {
    border-top: none !important;
  }

  &::after {
    border-top: none !important;
  }

  .mbsc-form-control-label {
    color: #009cde;
    font-weight: 600;
  }

  .mbsc-form-control-label {
    font-size: 13px !important;
    margin-left: 7px;
  }
}
