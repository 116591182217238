@use "@angular/material" as mat;
@import "@angular/material/theming";
@include mat.core();

$my-app-primary: mat-palette($mat-blue);
$my-app-accent: mat-palette($mat-yellow, A200, A100, A400);
$my-app-warn: mat-palette($mat-red);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);

mat-dialog-container {
  padding: 0 !important;
}
