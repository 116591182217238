.cat-table {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #dedede;

  .mat-header-row {
    background-color: rgb(249 250 251);
    .mat-header-cell {
      color: #000;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .mat-row:hover {
    .icon-edit {
      visibility: visible;
    }
  }
}

.mat-row {
  .mat-cell {
    cursor: pointer;
  }
}
