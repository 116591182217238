.working-shift-calendar {
  .mbsc-event-list {
    display: none;
  }

  .mbsc-schedule-date-header {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .reservation-detail {
    .mbsc-calendar-wrapper {
      position: fixed;
      top: 119px;
      z-index: 1000;
    }

    .mbsc-event-list {
      margin-top: 120px;
    }
  }
}

.mbsc-schedule-grid-wrapper {
  height: calc(100vh - 384px);
}

app-equipment-detail {
  .mbsc-schedule-grid-wrapper {
    height: calc(100vh - 499px);
  }
}

.reservation-detail {
  .mbsc-calendar-header {
    display: flex !important;
    justify-content: center !important;
  }

  .mbsc-calendar-month,
  .mbsc-calendar-title {
    display: none !important;
  }
}

.mbsc-timeline-resource {
  min-height: 130px !important;
}
